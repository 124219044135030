import React, { useState } from 'react';
import { Container, Form, Row } from 'reactstrap';
import Logo from '../../components/logo/Logo';
import { Link } from 'react-router-dom';
import Button from '../../components/button/Button';
import InputText from '../../components/inputText/InputText';
import query from '../../query';
import Loader from '../../components/loader/Loader';
import StatusMessage from '../../components/statusMessage/StatusMessage';
import InputMask from 'react-input-mask';
import messages from '../../config/constant/messages';
import { Mutation } from '@apollo/react-components';

const FindAccount = () => {
  const [values, setValues] = useState({
    email: '',
    ssn: '',
    dob: '',
    formErrors: { email: '', ssn: '', dob: '' },
  });

  // const [fetchAccount, { loading, data, error }] = useLazyQuery(query.locateAccount);

  const [fetchAccountError, setFetchAccountError] = useState(false);

  // if (data) {
  //   var customerId = data.locateAccount.customerId;
  //   return <AccountFound customerid={customerId} />;
  // }

  // if (error && !fetchAccountError) {
  //   setFetchAccountError({
  //     message: error.graphQLErrors.length
  //       ? error.graphQLErrors.map(e => e.message)
  //       : messages.default,
  //     color: 'danger',
  //   });
  // }

  const validate = e => {
    e.preventDefault();
    let status = true;

    if (values.email !== '') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if(!emailRegex.test(values.email)) {
        status = false;
        setFetchAccountError({ 
          message: 'Please enter a valid email address.', 
          color: 'danger' 
        });
      }
    }
    else if (values.ssn === '' || values.dob === '') {
      status = 'false';
      setFetchAccountError({ message: messages.required, color: 'danger' });
    }
    return status;
  };

  const formattedDob = (dobdate) => {
    var date = new Date(dobdate);
    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    if(year && month && day) {
      return year + '-' + month + '-' + day;
    }
    return '';
  }


  const handleSubmit = async (e, submitForm) => {
    e.preventDefault();
    if (validate(e) === true) {
      try {
        const { data: mutationData } = await submitForm({
          variables: { ssn: values.ssn, dateOfBirth: formattedDob(values.dob), email: values.email },
        });

        if (mutationData) {
          e.preventDefault();
          setFetchAccountError({
            message: messages.findAccount.findAccountSuccess,
            color: 'success',
          });
        }
      } catch (err) {
        setFetchAccountError({
          message: err.graphQLErrors.length
            ? err.graphQLErrors.map(e => e.message)
            : messages.default,
          color: 'danger',
        });
      }
    }
  };

  // e => {
  //   e.preventDefault();
  //   if (validate(e) === true) {
  //     fetchAccount({
  //       variables: { ssn: values.ssn, dateOfBirth: values.dob },
  //     });
  //   }
  // };

  const handleChange = e => {
    e.preventDefault();
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    let formErrors = values.formErrors;
    switch (name) {
      case 'ssn':
        formErrors.ssn = value.length < 2 ? 'required' : '';
        break;
      case 'dob':
        formErrors.dob = value.length < 8 ? 'minimum 8 character ' : '';
        break;
      case 'email':
        formErrors.email = value.length < 7 ? 'insert a valid email' : '';
        break;
      default:
        break;
    }
  };

  return (
    <Mutation mutation={query.registerAccount}>
      {(submitForm, { loading }) => (
        <Container>
          <React.Fragment>
            <Form onSubmit={e => handleSubmit(e, submitForm)} className="align-element-center py-5">
              <div className="col-lg-4 col-12 offset-lg-4 col-md-6 offset-md-3 text-center">
                <Logo />
                <div className="mt-4">
                  <h3 className="mb-3 heading">
                    <b>Let's find your account</b>
                  </h3>
                  <span className="below-headint-text">Please enter your information below</span>
                </div>
                {loading && <Loader />}
                {fetchAccountError && (
                  <StatusMessage
                    color={fetchAccountError.color}
                    value={fetchAccountError.message}
                  />
                )}
                <div className="mt-1 social_security_number">
                  <InputText
                    type="text"
                    name="email"
                    id="email"
                    className="pl-text text-font-size inspectletIgnore no-upscope"
                    placeholder="Email"
                    value={values.email}
                    errorsMessage={values.formErrors ? values.formErrors.email : ''}
                    onChange={handleChange}
                    required={false}
                  />
                  <div className="py-4">
                    <b>OR</b>
                  </div>
                  <InputText
                    type="text"
                    name="ssn"
                    id="ssn"
                    className="pl-text text-font-size inspectletIgnore no-upscope"
                    placeholder="Social Security Number"
                    value={values.ssn}
                    errorsMessage={values.formErrors ? values.formErrors.ssn : ''}
                    onChange={handleChange}
                    required={values.email === '' ? true : false}
                    mask="999-99-9999"
                    maskChar=" "
                    tag={InputMask}
                  />
                  <InputText
                    type="text"
                    name="dob"
                    id="dob"
                    className="text-font-size"
                    placeholder="DOB (mm-dd-yyyy)"
                    value={values.dob}
                    errorsMessage={values.formErrors ? values.formErrors.dob : ''}
                    onChange={handleChange}
                    required={values.email === '' ? true : false}
                    mask="99/99/9999"
                    maskChar=" "
                    tag={InputMask}
                  />
                </div>
                <Row className="justify-content-between align-items-center mt-4 mt-lg-5 ml-0 mr-0">
                  <div className="mr-2 link-div">
                    <Link to="/" title="Cancel" className="cancel-link">
                      Cancel
                    </Link>
                  </div>
                  <div className="login-btn ml-2">
                    <Button
                      type="submit"
                      title="Find My Account"
                      hvrtitle="Find My Account Button"
                    ></Button>
                  </div>
                </Row>
              </div>
            </Form>
          </React.Fragment>
        </Container>
      )}
    </Mutation>
  );
};

export default FindAccount;
